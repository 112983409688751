import Swiper from 'swiper/bundle';

const headerSlider = new Swiper('.header-slider', {
	autoplay: {
		delay: 5000
	},
	loop: true,
	pagination: {
		el: '.swiper-pagination',
		clickable: true
	},
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev'
	},
	// And if we need scrollbar
	scrollbar: {
		el: '.swiper-scrollbar'
	}
});
const indexProductsSlider = new Swiper('.index-products-slider', {
	autoplay: {
		delay: 5000
	},
	loop: true,
	slidesPerView: 'auto',
	spaceBetween: 30
});
const indexAchievementsSlider = new Swiper('.index-achievements-slider', {
	autoplay: {
		delay: 5000
	},
	loop: true,
	slidesPerView: 1,
	spaceBetween: 30,
	pagination: {
		el: '.swiper-pagination',
		clickable: true
	}
});
const indexReviewsSlider = new Swiper('.index-reviews-slider', {
	autoplay: {
		delay: 5000
	},
	loop: true,
	slidesPerView: 1,
	spaceBetween: 30,
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev'
	}
});
const productsNavSlider = new Swiper('.products-nav-slider', {
	slidesPerView: 'auto',
	spaceBetween: 10,
	freeMode: true,
	slideToClickedSlide: true,
	on: {
		init: function () {
			const firstScrollSpyEl = document.querySelector(
				'#slider-nav-scrollspy'
			);
			firstScrollSpyEl.addEventListener('activate.bs.scrollspy', (e) => {
				let slide = e.relatedTarget.parentElement.parentElement;
				let slidesParent = slide.parentElement;
				let index = Array.prototype.slice
					.call(slidesParent.childNodes)
					.indexOf(slide);
				productsNavSlider.slideTo(index);
			});
		}
	}
});
const navSlider = new Swiper('.nav-slider', {
	slidesPerView: 'auto',
	spaceBetween: 10,
	freeMode: true,
	slideToClickedSlide: true
});
const partnershipSlider = new Swiper('.partnership-slider', {
	autoplay: {
		delay: 5000
	},
	loop: true,
	slidesPerView: 1,
	spaceBetween: 30,
	pagination: {
		el: '.swiper-pagination',
		clickable: true
	}
});
const partnersSlider = new Swiper('.partners-slider', {
	autoplay: {
		delay: 5000
	},
	slidesPerView: 'auto',
	spaceBetween: 10,
	freeMode: true
});
const partnershipPageFeaturesSlider = new Swiper(
	'.partnership-page-features-slider',
	{
		autoplay: {
			delay: 5000
		},
		slidesPerView: 'auto',
		spaceBetween: 30,
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev'
		}
	}
);
const partnershipPagePresentSlider = new Swiper(
	'.partnership-page-present-slider',
	{
		autoplay: {
			delay: 5000
		},
		loop: true,
		slidesPerView: 1,
		spaceBetween: 30,
		pagination: {
			el: '.swiper-pagination',
			clickable: true
		},
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev'
		}
	}
);
const initSlidersByAttrs = () => {
	const sliders = document.querySelectorAll('.attributes-swiper');
	if (sliders.length > 0) {
		sliders.forEach((slider) => {
			let optionsData = slider.dataset.swiper
				? JSON.parse(slider.dataset.swiper)
				: {};
			new Swiper(slider, optionsData);
		});
	}
};
initSlidersByAttrs();
if (document.querySelector('.tariffs-swiper')) {
	var initTariffsSwiper = (vertical) => {
		const horizontalOpts = {
			loop: true,
			slidesPerView: 1,
			spaceBetween: 30,
			autoHeight: true,
			pagination: {
				el: '.swiper-pagination',
				clickable: true
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev'
			}
		};
		const verticalOpts = {
			direction: 'vertical',
			slidesPerView: 'auto',
			freeMode: true
		};
		return new Swiper(
			'.tariffs-swiper',
			vertical ? verticalOpts : horizontalOpts
		);
	};
	let tariffsSwiper;
	var tariffsSwiperMode = () => {
		let vertical;
		if (window.matchMedia('(min-width: 992px)').matches) {
			vertical = true;
		} else {
			vertical = false;
		}
		if (tariffsSwiper) {
			tariffsSwiper.destroy(false, true);
		}
		tariffsSwiper = initTariffsSwiper(vertical);
	};
	window.addEventListener('load', function () {
		tariffsSwiperMode();
	});
	window.addEventListener('resize', function () {
		tariffsSwiperMode();
	});
}
